<script lang="ts">
	export let className = '';
	export let style = '';
	export let borderRadius = '24px';
	export let as: 'div' | 'button' = 'div';

	export let role = 'button';
	export let disabled = false;
	
	let ariaChecked = false;
	export { ariaChecked as 'aria-checked' };
</script>

<svelte:element
	this={as}
	class="gradient-border {className}"
	style={style + ';--border-radius: ' + borderRadius + ';'}
	on:click
	role={as === 'div' ? undefined : role}
	disabled={as === 'div' ? undefined : disabled}
	aria-checked={as === 'div' ? undefined : ariaChecked}
>
	<slot />
</svelte:element>

<style lang="scss">
	.gradient-border {
		position: relative;
		border-radius: var(--border-radius);
	}

	.gradient-border::before {
		position: absolute;
		content: '';
		inset: 0;
		border-radius: var(--border-radius);
		padding: 1px; /* control the border thickness */
		background: linear-gradient(
			180deg,
			var(--border-color, rgb(123, 123, 123, 0.5)),
			rgb(123, 123, 123, 0)
		);
		-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}
</style>
